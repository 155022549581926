<template>
  <div style="padding: 20px">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>
      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">筛选条件</div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="90px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent
            >
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label-width="120px" label="提现时间">
                    <el-date-picker
                      v-model="value1"
                      type="datetimerange"
                      :start-placeholder="withdraw_time"
                      :end-placeholder="handle_time"
                      :default-time="['12:00:00']"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :lg="12" :md="12" style="text-align: center">
                  <div class="ele-form-actions">
                    <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList"
                      >查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">数据列表</div>
              <!-- <el-button class="ml-min" type="primary"> 提现申请 </el-button> -->
              <div class="Datalist_text">
                累计提现:<span class="text_c">{{ totals }}</span>
              </div>
              <div class="Datalist_text">
                提现处理中:<span>{{ in_hand }}</span>
              </div>
            </div>
            <div></div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :cellStyle="cellStyle"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :total="total"
              :customsFromWhere="where"
              :loading="loading"
            >
              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:is_voucher="{ scope }">
                <span v-if="scope.row.status == 0">
                  <el-link
                    :underline="false"
                    type="primary"
                     v-auths="[
                    `${$config.uniquePrefix}finance:extract:status`,
                  ]"

                    @click="pass(scope.row)"
                    >通过</el-link
                  >
                  <el-link
                    :underline="false"
                    type="danger"
                      v-auths="[
                    `${$config.uniquePrefix}finance:extract:status`,
                  ]"
                    @click="refa(scope.row)"
                  >
                    拒绝
                  </el-link>
                </span>
              </template>
            </my-table>
          </div>

          <el-dialog  :visible.sync="dialogVisibles" width="30%">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入拒绝原因"
              v-model="reason"
            >
            </el-input>

            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisibles = false">取 消</el-button>
              <el-button type="primary" @click="refuse()"
                >确 定</el-button
              >
            </span>
          </el-dialog>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
// 引入自定义表格组件
import MyTable from "@/components/MyTable";
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";

// 权限
import { ShowTable } from "@/utils";
// 引入的接口
import {
  getVendordomain,
  unbinddomain,
  Bounddomain,
  withdrawalapplication,
  withdrawalstatistics,
  passid,
  refuses,
} from "@/api/operate";

export default {

  name: "index",
  // 支柱道具属性
  props: {},
  // 组件生效
  components: {
    MyTable,
    PackageSortElement,
  },
  // Data数据
  data() {
    return {
      datalis:{},
      //拒绝原因
      reason: "",
      //弹窗
      dialogVisibles: false,
      //正在处理中
      in_hand: 0,
      totals: 0,
      // 表格搜索条件
      withdraw_time: "",
      handle_time: "",
      where: {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      },
      value1: "",
      dialogVisible: false,
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading: true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true,
        },
        {
          label: "状态",
          prop: "status_label",
          isShow: true,
        },
        {
          label: "提现单号",
          prop: "odd",
          isShow: true,
        },
        {
          label: "提交时间",
          prop: "withdraw_time",
          isShow: true,
        },
        {
          label: "提交类型",
          prop: "extract_type",
          isShow: true,
        },
        {
          label: "提现金额",
          width: "200",
          prop: "extract_price",
          isShow: true,
        },
        {
          label: "提现处理时间",
          prop: "fail_time",
          isShow: true,
        },
        {
          label: "提现失败原因",
          prop: "is_bind",
          isShow: true,
        },
        {
          label: "操作",
          prop: "is_voucher",
          slot: "is_voucher",
          isShow: true,
        },
      ],

      // 列表设置弹窗
      ListSetupShow: false,
    };
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  // mounted() {
  //   this.getList();
  // },
   activated() {
    // console.log("666666");
    this.getList();
  },
  destroyed() {
    this.getList();
  },

  // 方法集合
  methods: {
    getList() {
      withdrawalstatistics().then((res) => {
        console.log(res);
        this.in_hand = res.data.in_hand;
        this.totals = res.data.total;
      });
      withdrawalapplication(this.where)
        .then(async (res) => {
          this.total = res.data.count;
          this.List = res.data.list;
          console.log(this.List);
          this.loading = false;
        })
        .catch((res) => {
          this.$Message.error(res.msg);
          this.loading = false;
        });
    },
    untie(value, row) {},
    unties() {},

    // 查询条件
    QueryList() {
      this.getList();
    },
    // 清空
    empty() {
      this.where = {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      };
      this.value1 = '';
      this.withdraw_time = '';
      this.handle_time = '';
      this.getList();
    },
    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name";
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA";
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name";
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 点击列表设置
    ListSetup() {
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose() {
      this.ListSetupShow = false;
    },
    handleClose(done) {
      this.dialogVisible = false;
    },
    pass(row) {
      passid(row.id).then((res) => {
        if (res.code == 200) {
          this.getList();
          this.$Message.success(res.msg);
        }
      });
    },
    refuse() {
      let data = {
        reason:this.reason
      }
      refuses(this.datalis.id,data).then((res) => {
        if (res.code == 200) {
          this.getList();
          this.$Message.success(res.msg);
          this.dialogVisibles = false;
        }else{
          this.$Message.error(res.msg);
        }
      });
    },
    refa(row){
      this.datalis = row;
      this.dialogVisibles = true;
      this.reason = '';
    }
  },
};
</script>

<style scoped lang="scss">
.title {
  font-size: 24px;
  font-weight: 500;
  color: #707070;
  margin-bottom: 20px;
}

.condition {
  .condition_t {
    display: flex;
    align-items: center;
  }
  .condition_t_icon {
    width: 5px;
    height: 19px;
    background: #ff9b05;
  }
  .condition_t_text {
    font-size: 20px;
    font-weight: 700;
    color: #202033;
    margin-left: 15px;
  }
}
.flex {
  display: flex;
  align-items: center;
}
.w-50 {
  width: 400px;
}
.youbianjudingshirenwu {
  margin-left: 15px;
}
.zuobianjudingshirenwu {
  margin-right: 15px;
}
.ml-min {
  margin-left: 20px;
}
.text_c {
  color: #feae37;
}
</style>
